
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




























































.image.block {
	.block-inner {
		display: flex;
		flex-flow: row wrap;

		&.align-center {
			justify-content: center;
		}

		&.align-right {
			justify-content: flex-end;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
}
